
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<!-- <form @submit.prevent="addOrderItems" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="order_items.order_sku_code" type="text" :label="$store.getters.language.data.order_items.order_sku_code" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="order_items.order_item_price" type="text" :label="$store.getters.language.data.order_items.order_item_price" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['pending','arrived','out of stock','stock']" v-model="order_items.order_item_status" dense  filled outlined  item-text="order_item_status"
                                    item-value="order_item_status" :return-object="false" :label="$store.getters.language.data.order_items.order_item_status">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="order_items.order_item_quantity" type="number" :label="$store.getters.language.data.order_items.order_item_quantity" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="order_items.order_item_note" type="textarea" :label="$store.getters.language.data.order_items.order_item_note" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="orders" v-model="order_items.order_id" dense  filled outlined  item-text="order_id"
                                    item-value="order_id" :return-object="false" :label="$store.getters.language.data.orders.order_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="users" v-model="order_items.user_id" dense  filled outlined  item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.order_items.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form> -->
			<h1 class="ma-3">Incomplete Items</h1>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" class="ma-3" append-icon="mdi-magnify" label="search" dense outlined hide-details></v-text-field>

					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="order_item_id">
								<template v-slot:[`item.order_item_price`]="{ item }">
									<div>
										$ {{ item.order_item_price }}
									</div>
								</template>
								<!-- template v-odel for order_item_status -->
								<template v-slot:[`item.order_item_status`]="{ item }">
									<div>
										<v-chip v-if="item.order_item_status == 'pending'" color="orange" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'packed'" color="green" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'incomplete'" color="red" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'purchased'" color="blue" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'soldout'" color="black" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'postponed'" color="black" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'delivered'" color="gray" dark>{{ item.order_item_status }}</v-chip>
										<v-chip v-if="item.order_item_status == 'soldout_told'" color="pink accent-1" dark>{{ item.order_item_status }}</v-chip>

									</div>
								</template>
								<!-- create a v-select for the order_item_status -->
								<!-- template for the order_item_link on click go to the link -->
								<template v-slot:[`item.order_item_link`]="{ item }">
									<div>
										<a :href="item.order_item_link" target="_blank">
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<v-icon v-on="on" v-if="item.order_item_link != null">mdi-link</v-icon>
													<v-icon v-if="item.order_item_link == null" color="red" v-on="on">mdi-link</v-icon>
												</template>
												<span>{{ item.order_item_link }}</span>
											</v-tooltip>

										</a>
									</div>
								</template>
								<!-- order_item_date format date and time -->
								<template v-slot:[`item.order_item_date`]="{ item }">
									<div>
										{{ new Date(item.order_item_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
                                <template v-slot:[`item.order_item_purchase_date`]="{ item }">
									<div v-if="item.order_item_purchase_date!=null">
										{{ new Date(item.order_item_purchase_date).toISOString().split('T')[0]}} {{ new Date(item.order_item_purchase_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.order_item_id`]="{ item }">
									<div>
										<v-btn icon :to="'/order_items-list/'+item.order_item_id" color="teal" class="mx-1" v-if="item.order_item_status != 'delivered' && item.order_item_status != 'packed' ">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectOrderItems(item)" v-if="item.order_item_status == 'pending' ">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteOrderItemsList">{{$store.getters.language.data.order_items.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.order_items.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.order_items.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteOrderItems(selected_order_items.order_item_id)">
						{{$store.getters.language.data.order_items.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from '../../requests/order_items.request.js'
	export default {
		data() {
			return {
				order_items: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_order_items: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.order_items.order_sku_code,
						align: 'start',
						sortable: true,
						value: 'order_sku_code',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_price,
						align: 'start',
						sortable: true,
						value: 'order_item_price',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_status,
						align: 'start',
						sortable: true,
						value: 'order_item_status',
					},
					{
						text: "qty",
						align: 'start',
						sortable: true,
						value: 'order_item_quantity',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_link,
						align: 'start',
						sortable: true,
						value: 'order_item_link',
					},
					{
						text: this.$store.getters.language.data.order_items.order_item_date,
						align: 'start',
						sortable: true,
						value: 'order_item_date',
					},
                    {
                        text: this.$store.getters.language.data.order_items.order_item_purchase_date,
                        align: 'start',
                        sortable: true,
                        value: 'order_item_purchase_date',

                    },
					{
						text: this.$store.getters.language.data.order_items.order_item_note,
						align: 'start',
						sortable: true,
						value: 'order_item_note',
					},
					{
						text: this.$store.getters.language.data.orders.order_id,
						align: 'start',
						sortable: true,
						value: 'order_id',
					},
					{

						text: this.$store.getters.language.data.customers.customer_username,
						align: 'start',
						sortable: true,
						value: 'customer_username',
					},
					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'order_item_id',
					}
				],
			}
		},
		computed: {
			orders() {
				return this.$store.getters.orders_list
			},
			users() {
				return this.$store.getters.users_list
			},

		},
		mounted() {
			this.readOrderItems();
		},
		methods: {
			addOrderItems() {
				this.loading_btn = true
				requests.createOrderItems(this.order_items).then(r => {
					if (r.status == 200) {
						this.order_items = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'OrderItems Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OrderItems',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteOrderItems(order_item_id) {
				requests.deleteOrderItems(order_item_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.order_item_id != order_item_id
						})
						this.snackbar = {
							value: true,
							text: 'OrderItems Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteOrderItemsList() {
				let ids = this.selected_rows.map(m => m.order_item_id)
				requests.deleteOrderItemsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.order_item_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' OrderItems Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readOrderItems() {
				this.loading = true
				requests.reportOrderItems("order_item_status", "incomplete").then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OrderItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectOrderItems(i) {
				this.selected_order_items = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    